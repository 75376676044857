<template>

  <div style="width:60%;margin: 50px auto">
    <div class="main-body-content">
      <div style="display: flex; align-items: flex-start">
        <div style="width: 200px; border: 1px solid #ddd; border-radius: 5px; height: calc(50vh + 125px);">

          <div style="padding: 10px; border-bottom: 1px solid #ddd; color: #000; background-color: #eee"></div>
          <div class="user-list-box" style="height: 100%;">
            <div class="user-list-item" v-for="user in users" @click="changeUser(user)">
              <img :src="user.avatarUrl" style="width: 30px; height: 30px; border-radius: 50%">
              <span style="flex: 1; margin-left: 10px;color: #3a8ee6" >{{ user.nickname }}</span>
            </div>
          </div>

        </div>
        <!--  中间部分  -->
        <div style="width: 100%; border: 1px solid #ddd; border-radius: 5px; background-color: #f1f1f1; margin: 0 10px;">
          <div style="padding: 20px 0; text-align: center; border-bottom: 1px solid #ddd; color: #000; background-color: #eee; height: 60px">
            {{ chatUser.nickname }}
          </div>
          <div class="im-message-box">
            <div v-for="message in messages" >
              <!--  右边的气泡 -->
              <div style="display: flex; flex-direction: row-reverse; align-items: flex-start" v-if="message.isMe">
                <img :src="message.fromUser.avatarUrl"
                     style="width: 40px; height: 40px; border-radius: 50%; margin-left: 10px">
                <div class="im-message im-message-right" v-html="message.text" ></div>
              </div>

              <!--  左边的气泡 -->
              <div style="display: flex; align-items: flex-start" v-else>
                <img :src="message.toUser.avatarUrl"
                     style="width: 40px; height: 40px; border-radius: 50%; margin-right: 10px">
                <div style="width: 100%">
                  <div style="color: #888; font-size: 12px; width: 50%">
                    {{ message.toUser.nickname}}
                  </div>
                  <div class="im-message im-message-left" v-html="message.text"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- 输入区域 -->
          <div style="padding: 10px 5px; border-top: 1px solid #ddd; display: flex; align-items: center; width: 100%;">
            <el-input type="textarea" v-model="text"></el-input>
            <el-button type="primary" @click="sendMessage" style="border: none;margin-left: 10px">发送</el-button>
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>

let socket;

export default {
  name: "Im",
  data() {
    return {
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      chatUser: {},
      users: [],
      messages: [],
      text: '',
      id: this.$route.query.id,
    }
  },
  created() {
    this.loadUsers()
    this.init()
  },
  beforeDestroy() {
    //关闭事件
    socket.onclose = function () {
      console.log("websocket已关闭");
    }
  },
  methods: {
    loadUsers(){
      this.request.get("/user").then(res =>{
        this.users=res.data.filter(u=>u.username !== this.user.username)
      })
    },
    changeUser(user){
      if (this.chatUser.username !== user.username){
        this.chatUser = user
        this.messages = []
      }
    },
    init() {
      const username = this.user.username;
      const _this = this
      const socketUrl = "ws://119.91.201.68:9090/imserver/" + username;
      // 开启一个websocket服务
      socket = new WebSocket(socketUrl);

      //打开事件
      socket.onopen = function () {
        console.log("websocket已打开");
      }

      // 接受消息事件
      socket.onmessage = function (msg) {
        const data = JSON.parse(msg.data)
        const fromUser = _this.users.find(u=>u.username === data.from)
        //只有 发来数据用户 是和当前 正在聊天用户 匹配时候,再去给页面上加数据
        if (data.from===_this.chatUser.username){
          _this.createMessage(_this.user, fromUser, data.text,false)
        }
      }

    },
    sendMessage() {
      if (!this.chatUser.username) {
        this.$message({type: 'warning', message: "请选择聊天对象"})
        return;
      }
      if (this.text === '') {
        this.$message({type: 'warning', message: "请输入内容"})
        return;
      }
      const message = {from: this.user.username, to: this.chatUser.username, text: this.text}
      socket.send(JSON.stringify(message));
      this.createMessage(this.user, this.chatUser, this.text,true)
      this.text = ''
    },
    createMessage(fromUser, toUser, text , isMe) {
      const message = { fromUser, toUser, text ,isMe };
      this.messages.push(message);
      this.scrollToBottom()
    },
    scrollToBottom() {
      this.$nextTick(() => {
        // 设置聊天滚动条到底部
        let imMessageBox = document.getElementsByClassName("im-message-box")[0]
        //设置滚动条到最底部
        imMessageBox.scrollTop = imMessageBox.scrollHeight
      })
    },
  }
}
</script>


<style scoped>
.im-message-box {
  height: 50vh;
  padding: 10px;
  overflow-y: auto;
  background-color: white;
}

.user-list-box {
  overflow-y: auto;
}

.im-message-box::-webkit-scrollbar, .user-list-box::-webkit-scrollbar {
  width: 4px;
}

.im-message-box::-webkit-scrollbar-thumb, .user-list-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.im-message-box::-webkit-scrollbar-track, .user-list-box::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.im-message {
  font-size: 14px;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  color: #000;
  max-width: 50%;
  line-height: 20px;
  width: fit-content;
}

.im-message-left {
  background-color: #4d65bf;
}

.im-message-right {
  background-color: #26d470;
}

.user-list-item {
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  font-size: 14px;
}

.user-list-item:nth-last-child(1) {
  border: none;
}
</style>
